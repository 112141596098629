import { useState, useEffect, useContext } from 'react';
import { HeaderAtom } from '../../atoms/atomSample';
import { ProductConsumer, ProductContext } from './ProductContext';
import { useRecoilState } from 'recoil';
import Study from '../../storeItem/Sample';
import AccountProduct from '../../storeItem/AccountProduct';
import {reqGet} from '../../service';
import { CartAtom } from '../../atoms/accountAtom';
import {useLocation} from "react-router-dom";
import './product.css';

export default function Product() {
    const { lotGridRows, setLotGridRows } = useContext(ProductContext); //모듈 전역 컨텍스트
    const [item_sample, setItem_sample] = useState();
    const location = useLocation();
    return (
        <div style={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
            <SubHome location={location}/>
            {/* <ProductConsumer /> */}
        </div>
    )
}

export function SubHome() {
    const { lotGridRows, setLotGridRows } = useContext(ProductContext); //모듈 전역 컨텍스트
    const [CartItem, setCartItem] = useRecoilState(CartAtom);//CartItem
    const [total, setTotal] = useState(0);//CartItem
    useEffect(()=>{
        SumPrice();
    },[])
    const SumPrice=()=>{
        if(CartItem){
            var sumValue = total;
            CartItem.forEach(item => {
                sumValue = sumValue+=item.price;
            });
        }
        setTotal(sumValue);
    }
    return (
        <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
            <div className='CartBox'>
                    <ul style={{listStyleType:'none' ,padding:'0px'}}>
                        <h1> 결제 상품 목록 </h1>
                        {CartItem.map((item,idx)=>{
                            const totalPrice = item.price - 0;
                            const monthlyPrice = totalPrice/5;
                        return(<li>
                    <section style={{
                        display: 'flex',
                        padding: '1rem',
                        marginTop: '2.5rem',
                        marginBottom: '2rem',
                        margin: '1rem',
                        width: '300px',
                        background: '#fff',
                        borderRadius: '10px',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin:'4px',
                            width: '100%'
                        }}>
                            <label><b>상품명: </b></label>
                            <label><b>{item.title}</b></label>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin:'4px',
                            width: '100%'
                        }}>
                            <label>상품 금액: </label>
                            <label>{item.price}원</label>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin:'4px',
                            width: '100%'
                        }}>
                            <label>총 할인 금액: </label>
                            <label style={{color:'red'}}>-0원</label>
                        </div>
                        <div kind="default" className="css-16"></div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin:'4px',
                            width: '100%'
                        }}>
                            <label>총 결제 금액: </label>
                            <label style={{color:'black'}}>{totalPrice}원</label>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin:'4px',
                            width: '100%'
                        }}>
                            <label style={{color:'gray'}}>5개월 할부 시: </label>
                            <label style={{color:'gray'}}>월 {monthlyPrice}원</label>
                        </div>
                    </section>
                        </li>
                        )})}
                        <li>
                            <section style={{display:'flex',padding:'1rem',margin:'1rem',border: '3px solid #7078c2', width:'300px',background:'#fff',borderRadius:'10px',borderStyle:'solid',borderWidth:'1px',justifyContent:'space-between'}}>
                                        <div style={{background:'#fff',borderRadius:'15px'}}><label></label><label><b>가격 합계:</b></label></div>
                                        <div><label></label><label>{total}<b>원</b></label></div>
                            </section>
                        </li>
                        <li>
                            <AccountProduct/>
                        </li>
                    </ul>
            </div>
        </div>
    )
}