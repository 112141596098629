import React, { useState, createContext, useContext,useEffect } from 'react';

export const AuthContext = createContext({
    isLoggedIn: false,
    user: null,
    logout: () => {},
    setUser: () => {}
  });
  
  export const useAuth = () => useContext(AuthContext);
  
  export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
  
    useEffect(() => {
      const storedUser = sessionStorage.getItem('user');
      if (storedUser) {
        const userData = JSON.parse(storedUser);
        setIsLoggedIn(true);
        setUser(userData);
      }
    }, []);

    const login = (userData) => {
      sessionStorage.setItem('user', JSON.stringify(userData));
      setIsLoggedIn(true);
      setUser(userData);
    };
    const logout = () => {
      sessionStorage.removeItem('user');
      setIsLoggedIn(false);
      setUser(null);
    };
    
    
  
    return (
      <AuthContext.Provider value={{ isLoggedIn, user, login, logout }}>
        {children}
      </AuthContext.Provider>
    );
  };
  