import { createContext, useContext, useState } from 'react';

const ProdDetailPageContext = createContext();

const ProdDetailPageProvider = ({ children }) => {
  const [lotGridRows, setLotGridRows] = useState('');

  return (
    <ProdDetailPageContext.Provider value={
      { lotGridRows, setLotGridRows }
    }>
      {children}
    </ProdDetailPageContext.Provider>
  );
};

const ProdDetailPageConsumer = ({ children }) => {
  const value = useContext(ProdDetailPageContext);

  return (
    <div>
      Lot grid rows: {value.lotGridRows}
    </div>
  );
};

export { ProdDetailPageProvider, ProdDetailPageConsumer, ProdDetailPageContext };
