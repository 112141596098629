import { useState, useEffect, useContext } from 'react';
import { HeaderAtom } from '../../atoms/atomSample';
import { useRecoilState } from 'recoil';
import Study from '../../storeItem/Sample';
import AccountProduct from '../../storeItem/AccountProduct';
import {reqGet} from '../../service';
import { CartAtom } from '../../atoms/accountAtom';
import {useLocation} from "react-router-dom";
import { AdminConsumer, AdminContext } from './AdminContext';
export default function Admin() {
    const { lotGridRows, setLotGridRows } = useContext(AdminContext); //모듈 전역 컨텍스트

    return (
        <div>
            Ants Front Tamplete{lotGridRows}
        </div>
    )
}