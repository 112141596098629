import { useState, useEffect, useContext } from 'react';
import { HeaderAtom } from '../../atoms/atomSample';
import {useNavigate,Link } from "react-router-dom";
import { useRecoilState } from 'recoil';
import modeling from '../../resource/modeling.jpg';
import coding from '../../resource/coding.png';
import substance from '../../resource/substance.png';
import ZBrush from '../../resource/zcar.png';
import python from '../../resource/python.jpeg';
import javascript from '../../resource/javascript.jpg';
import { AuthContext, useAuth } from "../../authContext";
import { CartAtom } from '../../atoms/accountAtom';
import { reqGet,reqPost } from '../../service';
import './style.css';

const CourseCard = ({ title,price,prodImg,AddItem,CartList }) => {
    
    const navigate = useNavigate();
    const purchase=(item)=>{
        var ItemListArray = [item];
        AddItem(ItemListArray);
        navigate('/Product',{state:[item]});
    }
    // const cart=(newItem)=>{
    //     var ItemListArray = [...CartList, newItem];
    //     //상품 등록
    //     AddItem(ItemListArray);
    // }

    const cart = (newItem) => {
        let itemFound = false;
        const updatedCart = CartList.map((item) => {
            if (item.title === newItem.title) {
                itemFound = true;
                return { ...item, quantity: item.quantity ? item.quantity + 1 : 2 };
            }
            return item;
        });
    
        if (!itemFound) {
            AddItem([...CartList, { ...newItem, quantity: 1 }]);
        } else {
            AddItem(updatedCart);
        }
    };

    return (
        <div style={{display:'flex',flexDirection:'column'}} className="bg-white rounded-lg shadow p-4">
            <div style={{display:'contents'}} className="h-40 bg-gray-300 rounded">
                <Link to={'ProdDetailPage'}>
                    <img src={prodImg} style={{display:'flex', width:'100%', aspectRatio:' auto 1 / 1', borderRadius:'15px'}}/>
                </Link>
            </div>
            <h2 className="mt-2 text-lg font-semibold">{title}</h2><h2 className="mt-2 text-lg text-left -semibold">가격: {price}원</h2>
            <div className="button-container" style={{display:'block', flexDirection:'row'}}>
            <button type="button"
                onClick={()=>cart({title:title,price:price})}>장바구니</button>
                <button type="button"
                onClick={()=>purchase({title:title,price:price})}>구매하기</button>
            </div>
        </div>
    );
};

export default function Store() {
    const [CartItem, setCartItem] = useRecoilState(CartAtom);//CartItem
    const [dynamicItem,setDynamicItem] = useState([{title:''}]);
    const [activeTab, setActiveTab] = useState('Coding');
    const [hoverItemIndex, setHoverItemIndex] = useState(-1);
    const { login,user } = useAuth();
    const navigate = useNavigate();
    useEffect(()=>{
        //reqPost('api/getProduct_List',{},(result, objectParams)=>console.log("결과:"+JSON.stringify(result)+'/'+JSON.stringify(objectParams)));
        reqPost('api/getProduct_List',{type:activeTab},(result, objectParams)=>setDynamicItem(result));
    },[activeTab])

    const updateQuantity = (itemToUpdate, delta) => {
        const updatedCart = CartItem.map((item) => {
            if (item.title === itemToUpdate.title) {
                const updatedQuantity = item.quantity + delta;
                return { ...item, quantity: updatedQuantity > 0 ? updatedQuantity : 1 };
            }
            return item;
        });
    
        setCartItem(updatedCart);
    };

    const getTotalPrice = () => {
        return CartItem.reduce((total, item) => total + item.price * (item.quantity || 1), 0);
    };

    const deleteItem = (itemToDelete) => {
        const updatedCart = CartItem.filter(item => item.title !== itemToDelete.title);
        setCartItem(updatedCart);
    };

return(
    <div>
       
        <div class='container'>
     
        <div id='layout' style={{
                                width:'65%',
                                padding: '30px',
                                borderRadius: '15px',
                                background:'#fff',
                                boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)'
                                }}>
            
            <div className="tabs">
                <button className={`tab ${activeTab === 'Coding' ? 'active' : ''}`} onClick={() => setActiveTab('Coding')}>Programing</button>
                <button className={`tab ${activeTab === 'Graphics' ? 'active' : ''}`} onClick={() => setActiveTab('Graphics')}>Graphics</button>
                <button className={`tab ${activeTab === 'DataBase' ? 'active' : ''}`} onClick={() => setActiveTab('DataBase')}>DataBase</button>
                <button className={`tab ${activeTab === 'DavOps' ? 'active' : ''}`} onClick={() => setActiveTab('DavOps')}>DevOps</button>
                <button className={`tab ${activeTab === 'Infra' ? 'active' : ''}`} onClick={() => setActiveTab('Infra')}>Infra</button>
            </div>
            <div>
                {activeTab === 'Coding' && (
                    <div id='coursecard' className="grid grid-cols-1 md:grid-cols-3 gap-4">
                       <CourseCard title={'C# 입문'} prodImg={coding} AddItem={setCartItem} CartList={CartItem} price={50000}/>
                       <CourseCard title={'Java 심화'} prodImg={javascript} AddItem={setCartItem} CartList={CartItem}  price={10000}/>
                       <CourseCard title={'Python 기초'} prodImg={python} AddItem={setCartItem} CartList={CartItem} price={10000}/>
                       <CourseCard title={'JavaScript'} prodImg={javascript} AddItem={setCartItem} CartList={CartItem} price={10000}/>
                       <CourseCard title={'Unity3D 입문'}  prodImg={modeling} AddItem={setCartItem} CartList={CartItem} price={10000}/>
                       {dynamicItem.map((item,idx)=>(<CourseCard title={item.prod_name} AddItem={setCartItem} CartList={CartItem} price={item.prod_price} key={idx}></CourseCard>))}
                    </div>
                )}
                {activeTab === 'Graphics' && (
                   <div id='coursecard' className="grid grid-cols-1 md:grid-cols-3 gap-4">
                       <CourseCard title={'3D-Max 입문 강의'}  prodImg={modeling}/>
                       <CourseCard title={'Blender 입문 강의'}  prodImg={modeling}/>
                       <CourseCard title={'ZBrush 입문 강의'} prodImg={ZBrush} />
                       <CourseCard title={'SubstancePainter'} prodImg={substance}/>
                       <CourseCard title={'PhotoShop 심화 강의'} prodImg={substance}/>
                       {dynamicItem.map((item,idx)=>(<CourseCard title={item.prod_name} AddItem={setCartItem} CartList={CartItem} price={item.prod_price} key={idx}></CourseCard>))}
                   </div>
                )}
                {activeTab === 'DevOps' && (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <CourseCard title={'Github'} />
                        <CourseCard title={'Docker'} />
                        <CourseCard title={'Supabase'} />
                        <CourseCard title={'Kubernetes'} />
                        <CourseCard title={'Jenkins'} />
                        <CourseCard title={'Svn'} />
                        {dynamicItem.map((item,idx)=>(<CourseCard title={item.prod_name} AddItem={setCartItem} CartList={CartItem} price={item.prod_price} key={idx}></CourseCard>))}
                </div>
                )}
                {activeTab === 'DataBase' && (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <CourseCard title={'Oracle'} />
                    <CourseCard title={'Ms-Sql'} />
                    <CourseCard title={'MariaDb'} />
                    <CourseCard title={'PostGreSql'} />
                    <CourseCard title={'MongoDB'} />
                    <CourseCard title={'Cassandra'} />
                    <CourseCard title={'Neo4j'} />
                    {dynamicItem.map((item,idx)=>(<CourseCard title={item.prod_name} AddItem={setCartItem} CartList={CartItem} price={item.prod_price} key={idx}></CourseCard>))}
                </div>
                )}
                {activeTab === 'Infra' && (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <CourseCard title={'Windows Server'} />
                    <CourseCard title={'Linux'} />
                    <CourseCard title={'XOS'} />
                    <CourseCard title={'Network'} />
                    <CourseCard title={'Aws'} />
                    <CourseCard title={'Gcp'} />
                    <CourseCard title={'Azure'} />
                    {dynamicItem.map((item,idx)=>(<CourseCard title={item.prod_name} AddItem={setCartItem} CartList={CartItem} price={item.prod_price} key={idx}></CourseCard>))}
                </div>
                )}
            </div>
       </div>
       
        <div id = 'list' style={{width:'30%'}} className="flex flex-wrap -mx-2">
            <div style={{width:'100%'}}>
            <div  className="bg-white rounded-lg shadow p-4 mb-4">
                    <h2 className="text-xl font-semibold mb-3">내 상품 리스트</h2>
                  
                </div>
                
                <div hidden={user?false:true} className="bg-white rounded-lg shadow p-4 mb-4">
                    <h2 className="text-xl font-semibold mb-3">앤츠코인 충전하기</h2>
                    <div style={{justifyContent:'space-between'}} className="flex justify-between items-center mb-4">
                        <span  style={{width:'30%',alignSelf:'center',fontWeight:'900'}} className="text-gray-700">앤 츠 코 인</span>
                        <span className="font-semibold">0 원</span>
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between'}}  className="mb-4">
                        <label style={{width:'30%',alignSelf:'center',fontWeight:'900'}} for="amount" className="block text-gray-700 mb-2">금 액 입 력</label>
                        <input style={{width:'40%',fontSize:'16px'}} type="text" id="amount" className="w-full px-3 py-2 border rounded" placeholder="금액을 입력하세요"/>
                    </div>
                    <button style={{width:'100%', fontSize: '16px', fontWeight:'bold',color: 'white', padding: '8px 0', borderRadius: '4px', border:'none', }} className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600">충전하기</button>
                </div>

                
                <div className="bg-white rounded-lg shadow p-4">
                    <h2 className="text-xl font-semibold mb-3">장바구니</h2>
                    <div style={{height:'auto'}} className="h-40 bg-gray-300 rounded mb-4 item-container">
                    {CartItem.map((item,idx)=>(<div style={{display:'flex',padding:'10px',justifyContent:'space-around'}} key={idx}
                        onMouseEnter = {() => setHoverItemIndex(idx)}
                        onMouseLeave = {() => setHoverItemIndex(-1)}
                    >
                        <span>{item.title}</span>
                        <span>
                            <button style={{marginRight: '10px'}} onClick={() => updateQuantity(item, -1)}>-</button>
                            {item.quantity || 1}
                            <button style={{marginLeft: '10px'}} onClick={() => updateQuantity(item, 1)}>+</button>
                        </span>
                        <span>{item.price*item.quantity}원</span>
                        <span>
                            {hoverItemIndex === idx && (
                                <button className="delete-button" onClick={() => deleteItem(item)}>X</button>
                            )}
                        </span>
                        </div>)) }
                    </div>
                    <div style={{marginBottom:'12px'}}>
                        Total: {getTotalPrice()}원
                    </div>
                    <button style={{fontSize: '16px', fontWeight:'bold',color: 'white', padding: '8px 0', borderRadius: '4px',width:'100%', border:'none', backgroundColor: '#00ADB5'}} onClick={()=>{
                        navigate('/Product',{state:CartItem});
                    }}>결제하기</button>
                </div>
            </div>
        </div>
        </div>
    </div>
)
}




     

