import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function processHTTP(mapParam) {
  try {
    console.log('요청파라미터:'+JSON.stringify(mapParam));
    console.log(mapParam.authToken);
    console.log(mapParam.authUrl);
    console.log(mapParam.mid);
    fetch('/AccountSign', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(mapParam)
    })
    .then((res) => res.json())
    .then((msg) => {
      console.log('리턴값:' + JSON.stringify(msg));
    })
    .catch((error) => {
      
      console.error('Error during HTTP request:', error);
    });
  } catch (error) {
    console.error('Error during HTTP request:', error);
  }
}



// function processHTTP(mapParam, url) {
//   try {
//     // 매개변수를 쿼리 문자열로 변환합니다.
//     const queryParams = new URLSearchParams(mapParam).toString();
//     const requestUrl = `${url}?${queryParams}`;
//     console.log('processHTTP CALL!!:', requestUrl);

//     fetch(requestUrl,  { credentials: 'include' }).then((res) => {
//       if (!res.ok) {
//         throw new Error(`Server responded with status code ${res.status}`);
//       }
//       return res.json();
//    }).then((msg) => {
//           console.log('Request Get uri:' + url + ': param:' + JSON.stringify(mapParam) + ':' + JSON.stringify(msg));
//       })
//       .catch(err => alert('Error' + err));;

//     return responseText;
//   } catch (error) {
//     console.error('Error during HTTP request:', error);
//     return null;
//   }
// }






function ReturnPay(props) {
  const [initParams, setInitParams] = useState(null);
  const location = useLocation();

  function parseJSON(jsonString) {
    try {
      // JSON 파싱
      const parsedData = JSON.parse(jsonString);
      return parsedData;
    } catch (error) {
      console.error("JSON 파싱 오류:", error);
      return null;
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramsString = queryParams.get('params');
    if (paramsString) {
      try {
        var decodeString= decodeURIComponent(paramsString);
        var JsonObject= parseJSON(decodeString);
        // URL에서 추출된 데이터를 디코딩하고 JSON 객체로 변환
       
        // 함수 사용 예시
        const mapParam = JsonObject;
        const url = JsonObject.authUrl;
        setInitParams(JsonObject);
        processHTTP(mapParam);
      } catch (error) {
        setInitParams(error);
      }
    }
  }, [location]);


  

  function parseStringToMap(str) {
    const map = {};
    // 문자열 파싱 로직 구현
    // 예: str을 분석하여 key-value 쌍을 추출하고 map 객체에 저장
    return map;
  }
    // 보안 서명 생성 함수
    function makeSignatureAuth(secureMap) {
    // 보안 서명 로직 구현
    // 예: secureMap 데이터를 사용하여 서명 문자열을 생성
    return "generated-signature";
    }

  
  async function computeHash(input) {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const hash = await crypto.subtle.digest("SHA-256", data);
    return Array.from(new Uint8Array(hash))
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('');
  }


  // StartINIStdReturn 함수 (async로 선언)
  const StartINIStdReturn = async (params) => {
    const resultCode = params.resultCode;
    const authUrl = params.authUrl;
    const idcName = params.idc_name;
    const mid = params.mid;
    const authToken = params.authToken;
    const netCancel = params.netCancelUrl;
    const merchantData = params.merchantData;
    const timestamp = Date.now().toString();
    const signKey = "SU5JTElURV9UUklQTEVERVNfS0VZU1RS";
    const signParam = `authToken=${authToken}&timestamp=${timestamp}`;
    const signature = await computeHash(signParam);

    const signParam2 = `authToken=${authToken}&signKey=${signKey}&timestamp=${timestamp}`;
    const verification = await computeHash(signParam2);

   

    console.log("##승인요청 API 요청##");

    // 설정된 승인 URL을 얻기 위한 방법을 결정해야 합니다.
    // 예를 들어, 환경 변수, 외부 설정 파일 등에서 가져올 수 있습니다.
    // 여기서는 하드코딩된 값으로 예시를 드립니다.
    const configuredAuthUrls = {
        'fc': '승인 URL for fc',
        'ks': '승인 URL for ks',
        'stg': '승인 URL for stg'
    };
    const expectedAuthUrl = configuredAuthUrls[idcName];

  if (resultCode === "0000" && authUrl === expectedAuthUrl) {
    console.log("####인증성공/승인요청####");
    try {
        // API 통신 시작
        const response = await fetch(authUrl, {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: {}
        });
    
        const authResultString = await response.text();
        console.log("## 승인 API 결과 ##", authResultString);
    
        // API 통신결과 처리
        // 결과 문자열을 적절히 파싱하여 필요한 정보를 추출합니다.
        // 여기서는 예시로 간단한 파싱 로직을 사용합니다.
        const resultMap = parseStringToMap(authResultString);
    
        // 결제보안 추가
        const secureMap = {
          mid: mid,
          tstamp: timestamp,
          MOID: resultMap.MOID,
          TotPrice: resultMap.TotPrice
        };
    
        // 보안 서명 생성
        // 결과를 상태에 저장하거나 다른 처리를 합니다.
        // 예: setState(resultMap);
        const secureSignature = makeSignatureAuth(secureMap);
        console.log("보안 서명: ", secureSignature);
        
      // if ("0000" === resultMap.resultCode) {
      //   if(secureSignature !== resultMap.authSignature) {
      //       setError("데이터 위변조 체크 실패");
      //   }
      //   // 거래 성공 로직
      //   setTransactionResult({ success: true, data: resultMap });
      // } else {
      //   // 거래 실패 로직
      //   setTransactionResult({ success: false, data: resultMap });
      // }

      // //공통로직

    // if (resultMap && resultMap["payMethod"] === "VBank") {
    //     // 'VBank'에 대한 로직 처리
    // }
    // else if (resultMap && resultMap["payMethod"] === "DirectBank") {
    //     // 실시간계좌이체
    // }
    // else if (resultMap && resultMap["payMethod"] === "HPP") {
    //     // 휴대폰
    // }
    // else if (resultMap && resultMap["payMethod"] === "KWPY") {
    //     // 뱅크월렛 카카오
    // }
    // else if (resultMap && resultMap["payMethod"] === "Culture") {
    //    //문화상품권
    // }
    // else if (resultMap && resultMap["payMethod"] === "DGCL") {
    //     //게임문화상품권
    //     // String sum = "0", sum2 = "0", sum3 = "0", sum4 = "0", sum5 = "0", sum6 = "0";
    //     if (resultMap && resultMap["payMethod"] === !"") {
    //         //문화상품권
    //      }
    //     if (resultMap && resultMap["GAMG_Num2"] && resultMap["GAMG_Num2"] !== "") {
    //         // 'GAMG_Num2'가 비어있지 않은 경우의 로직 처리
    //     }
    //     if (resultMap && resultMap["GAMG_Num3"] && resultMap["GAMG_Num3"] !== "") {
    //         // 'GAMG_Num2'가 비어있지 않은 경우의 로직 처리
    //     }
    //     if (resultMap && resultMap["GAMG_Num4"] && resultMap["GAMG_Num4"] !== "") {
    //         // 'GAMG_Num2'가 비어있지 않은 경우의 로직 처리
    //     }
    //     if (resultMap && resultMap["GAMG_Num5"] && resultMap["GAMG_Num5"] !== "") {
    //         // 'GAMG_Num2'가 비어있지 않은 경우의 로직 처리
    //     }
    //     if (resultMap && resultMap["GAMG_Num6"] && resultMap["GAMG_Num6"] !== "") {
    //         // 'GAMG_Num2'가 비어있지 않은 경우의 로직 처리
    //     }

    // }
    // else if (resultMap && resultMap["payMethod"] === "OCBPoint") {
    //     //오케이 캐쉬백
    //  }
    //  else if (resultMap && resultMap["payMethod"] === "GSPoint") {
    //     //GSPoint
    //  }
    //  else if (resultMap && resultMap["payMethod"] === "UPNT") {
    //     //U-포인트
    //  }
    //  else if (resultMap && resultMap["payMethod"] === "KWPY") {
    //    //뱅크월렛 카카오
    //  }
    //  else if (resultMap && resultMap["payMethod"] === "YPAY") {
    //    //엘로우 페이
    //  }
    //  else if (resultMap && resultMap["payMethod"] === "TEEN") {
    //     //틴캐시
    //   }
    // else if (resultMap && resultMap["payMethod"] === "Bookcash") {
    //     //도서문화상품권
    // }
    // else if (resultMap && resultMap["payMethod"] === "PhoneBill") {
    //     //폰빌전화결제
    // }
    // else if (resultMap && resultMap["payMethod"] === "Bill") {
    //     //빌링결제1
    // }
    // else if (resultMap && resultMap["payMethod"] === "Auth") {
    //     //빌링결제2
    //     if (resultMap && resultMap["payMethodDetail"] === "BILL_CARD")
    //     {
            
    //     }
    //     else if (resultMap && resultMap["payMethodDetail"] === "BILL_HPP")
    //     {
          

    //     } else {
    //         //
    //     }	
    // }

   
  
    // else if ("Auth".Equals((resultMap.ContainsKey("payMethod") ? resultMap["payMethod"] : "null")))
    // {//빌링결제
        
    //     if ("BILL_CARD".Equals((resultMap.ContainsKey("payMethodDetail") ? resultMap["payMethodDetail"] : "null")))
    //     {
           
    //     }
    //     else if ("BILL_HPP".Equals((resultMap.ContainsKey("payMethodDetail") ? resultMap["payMethodDetail"] : "null")))
    //     {
           
    //     } else {
    //         //
    //     }	
    // }
    // else
    // {//카드
    //     let quota = parseInt(resultMap["CARD_Quota"] || "0");

    //     if (resultMap["EventCode"]) {
    //         // EventCode가 존재하는 경우에 대한 로직
    //     }

    //     if (resultMap["CARD_Interest"] === "1" || resultMap["EventCode"] === "1") {
    //         // 카드 이자가 있거나, 이벤트 코드가 1인 경우의 로직
    //     } else if (quota > 0 && resultMap["CARD_Interest"] !== "1") {
    //         // 할부 기간이 있고 이자가 없는 경우의 로직
    //     }

    //     if (resultMap["point"] === "1") {
    //         // 포인트 사용인 경우의 로직
    //     } else {
    //         // 포인트 미사용인 경우의 로직
    //     }

    //     if (resultMap["OCB_Num"] && resultMap["OCB_Num"] !== "") {
    //         // OCB_Num이 존재하고 비어있지 않은 경우의 로직
    //     }

    //     if (resultMap["GSPT_Num"] && resultMap["GSPT_Num"] !== "") {
    //         // GSPT_Num이 존재하고 비어있지 않은 경우의 로직
    //     }

    //     if (resultMap["UNPT_CardNum"] && resultMap["UNPT_CardNum"] !== "") {
    //         // UNPT_CardNum이 존재하고 비어있지 않은 경우의 로직
    //     }
    // }
    
      } catch (error) {
        console.error("API 통신 중 오류 발생:", error);
        // 오류 처리 로직
      }


    // 인증 성공시의 로직을 여기에 추가합니다.
    // 예: API 요청, 상태 업데이트 등

  } else {
    console.log("인증 실패 또는 URL 불일치");
    // 인증 실패 또는 URL 불일치시의 로직을 여기에 추가합니다.
  }

    //const responseText = await response.text();
    //console.log("##승인요청 API 결과##", responseText);

     // 가맹점 ID와 다른 필요한 파라미터를 추출합니다.


  // 가맹점에 제공된 키
  };

  return (
    <form>
       {initParams && <pre>{JSON.stringify(initParams, null, 2)}</pre>}
       
      {/* <button type="submit">제출</button> */}
      
    </form>
  );
}

export default ReturnPay;