import {useState,useEffect} from 'react';
import logo from './logo.svg';

import './App.css';


function Pay() {
  const signKey = 'SU5JTElURV9UUklQTEVERVNfS0VZU1RS'; // Replace with your actual key
  const [formData, setFormData] = useState({
    version: '1.0',
    mid: 'INIpayTest',
    goodname: '테스트',
    price: '1000',
    currency: 'WON',
    buyername: '홍길동',
    buyertel: '010-1234-1234',
    buyeremail: 'test@inicis.com',
    timestamp: '1691636550526',
    oid: 'INIpayTest_1691636550526',
    signature: 'd1ada6bdd9d5a88ead9d3d7742e18ba141ecfcc1b7760584f5c979d6a973fefb',
    mKey: '3a9503069192f207491d4b19bd743fc249a761ed94246c8c42fed06c3cd15a33',
    use_chkfake: 'Y',
    verification: 'c839f8b065e1f24f9177b8936c78fa904e0e79d7fb9595a6dc0125eb9c9d26e6',
    gopaymethod: 'Card',
    offerPeriod: '20150101-20150331',
    acceptmethod: 'HPP(1):no_receipt:va_receipt:below1000:centerCd(Y)',
    languageView: '',
    charset: '',
    payViewType: '',
    nointerest: '',
    quotabase: '',
    INIregno: '',
    merchantData: ''
  });

  useEffect(() => {
    const currentOrigin = window.location.origin;
    setFormData(formData => ({
      ...formData,
      returnUrl: `${currentOrigin}/ReturnPay`,
      closeUrl: `${currentOrigin}/pay/close.aspx`
    }));
  
    // const timestamp = Date.now().toString();
    // const oid = `${formData.mid}_${timestamp}`;
    // computeHash(oid).then(signature => {
    //   setFormData(formData => ({ ...formData, timestamp, oid, signature }));
    // });
    // computeHash(signKey).then(mKey => {
    //   setFormData(formData => ({ ...formData, mKey }));
    // });
    
  }, [formData]); // formData를 의존성으로 추가// Empty dependency array ensures useEffect runs only once on mount


const updateVerification = async (oid,timestamp) => {
  const param2 = `oid=${formData.oid}&price=${formData.price}&signKey=${signKey}&timestamp=${formData.timestamp}`;
  const verificationHash = await computeHash(param2);
  setFormData({ ...formData, verification: verificationHash });
};

  // Dummy timestamp generation - Replace with server-side logic
  
  // Compute initial hash values
 

  // Compute mKey and verification - Replace signKey with your key
 
  

async function computeHash(input) {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
}
const handleSubmit = () => {
  alert('전송요청 서브밋 파라미터 :'+JSON.stringify(formData));
  const timestamp = Date.now().toString();
    const oid = `${formData.mid}_${timestamp}`;
  computeHash(oid).then(signature => {
    setFormData(formData => ({ ...formData, timestamp, oid, signature }));
  });
  computeHash(signKey).then(mKey => {
    setFormData(formData => ({ ...formData, mKey }));
  });
  updateVerification(oid,timestamp);
 window.INIStdPay.pay('SendPayForm_id');
  // 이 부분에 이니시스 결제 처리를 적용해주세요 (INIStdPay.pay).
};


  return (
    <>
    <form id="SendPayForm_id" >
      {JSON.stringify(formData)}
<div style={{ border: '2px #dddddd double', padding: '10px', backgroundColor: '#f3f3f3', textAlign: 'left' }}>
  <br />
  <b>***** 필 수 *****</b>

  <br />
  <b>version</b> :
  <br />
  <input id="version" name="version" type="text" value={formData.version} onChange={handleChange} />

  <br />
  <b>mid</b> :
  <br />
  <input id="mid" name="mid" type="text" value={formData.mid} onChange={handleChange} />

  <br />
  <b>goodname</b> :
  <br />
  <input id="goodname" name="goodname" type="text" value={formData.goodname} onChange={handleChange} />

  <br />
  <b>oid</b> :
  <br />
  <input id="oid" name="oid" type="text" value={formData.oid} onChange={handleChange} />

  <br />
  <b>price</b> :
  <br />
  <input id="price" name="price" type="text" value={formData.price} onChange={handleChange} />

  <br />
  <b>currency</b> :
  <br />
  [WON|USD]
  <br />
  <input id="currency" name="currency" type="text" value={formData.currency} onChange={handleChange} />

  <br />
  <b>buyername</b> :
  <br />
  <input id="buyername" name="buyername" type="text" value={formData.buyername} onChange={handleChange} />

  <br />
  <b>buyertel</b> :
  <br />
  <input id="buyertel" name="buyertel" type="text" value={formData.buyertel} onChange={handleChange} />

  <br />
  <b>buyeremail</b> :
  <br />
  <input id="buyeremail" name="buyeremail" type="text" value={formData.buyeremail} onChange={handleChange} />

  <br />
  <b>timestamp</b> :
  <br />
  <input id="timestamp" name="timestamp" type="text" value={formData.timestamp} onChange={handleChange} style={{ width: '400px' }} />

  <br />
  <b>signature</b> :
  <br />
  <input id="signature" name="signature" type="text" value={formData.signature} onChange={handleChange} style={{ width: '400px' }} />

  <br />
  <b>use_chkfake</b> :
  <br />
  <input id="use_chkfake" name="use_chkfake" type="text" value={formData.use_chkfake} onChange={handleChange} />

  <br />
  <b>verification</b> :
  <br />
  <input id="verification" name="verification" type="text" value={formData.verification} onChange={handleChange} style={{ width: '400px' }} />

  <br />
  <b>returnUrl</b> :
  <br />
  <input id="returnUrl" name="returnUrl" type="text" value={formData.returnUrl} onChange={handleChange} style={{ width: '400px' }} />

  <br />
  <b>MKEY(가맹점키)</b> :
  <br />
  <input id="mKey" name="mKey" type="text" value={formData.mKey} onChange={handleChange} />
</div>


<b>***** 기본 옵션 *****</b>
<div style={{ border: '2px #dddddd double', padding: '10px', backgroundColor: '#f3f3f3', textAlign: 'left' }}>
  <b>gopaymethod</b> : 결제 수단 선택
  <br />
  EX Card (계약 결제 수단이 존재하지 않을 경우 에러로 리턴)
  <br />
  사용 가능한 입력 값
  <br />
  Card,DirectBank,HPP,Vbank,kpay,Swallet,Paypin,EasyPay,PhoneBill,GiftCard,EWallet
  <br />
  onlypoint,onlyocb,onyocbplus,onlygspt,onlygsptplus,onlyupnt,onlyupntplus
  <br />
  <input id="gopaymethod" name="gopaymethod" type="text" value={formData.gopaymethod} onChange={handleChange} />
  <br />
  <br />

  <br />
  <b>offerPeriod</b> : 제공기간
  <br />
  20150101-20150331, [Y2: 년단위결제, M2: 월단위결제, yyyyMMdd-yyyyMMdd: 시작일-종료일]
  <br />
  <input id="offerPeriod" name="offerPeriod" type="text" value={formData.offerPeriod} onChange={handleChange} />
  <br />
  <br />

  <br />
  <b>acceptmethod</b> : acceptmethod
  <br />
   CARDPOINT:SLIMQUOTA(코드-개월:개월):no_receipt:va_receipt:vbank(20150425):va_ckprice:
  <br />
  KWPY_TYPE(0):KWPY_VAT(10|0) 기타 옵션 정보 및 설명은 연동정의보 참조 구분자 ":"
  <br />
  <input id="acceptmethod" name="acceptmethod" type="text" value={formData.acceptmethod} onChange={handleChange} style={{ width: '500px' }} />
</div>

{/* 표시 옵션 */}
<br />
<b>***** 표시 옵션 *****</b>
<div style={{ border: '2px #dddddd double', padding: '10px', backgroundColor: '#f3f3f3', textAlign: 'left' }}>
  <br />
  <b>languageView</b> : 초기 표시 언어
  <br />
  [ko|en] (default: ko)
  <br />
  <input id="languageView" name="languageView" type="text" value={formData.languageView} onChange={handleChange} />

  <br />
  <b>charset</b> : 리턴 인코딩
  <br />
  {/* [UTF-8|EUC-KR] (default: UTF-8) */}
  <br />
  <input id="charset" name="charset" type="text" value={formData.charset} onChange={handleChange} />

  <br />
  <b>payViewType</b> : 결제창 표시방법
  <br />
  [overlay] (default: overlay)
  <br />
  <input id="payViewType" name="payViewType" type="text" value={formData.payViewType} onChange={handleChange} style={{ width: '210px' }} />

  <br />
  <b>closeUrl</b> : payViewType='overlay','popup'시 취소버튼 클릭시 창닫기 처리 URL(가맹점에 맞게 설정)
  <br />
  close.jsp 샘플사용(생략가능, 미설정시 사용자에 의해 취소 버튼 클릭시 인증결과 페이지로 취소 결과를 보냅니다.)
  <br />
    <input id="closeUrl" name="closeUrl" type="text" value={formData.closeUrl} onChange={handleChange} style={{ width: '400px' }} />
  </div>
    <button type="button" onClick={()=>handleSubmit()}>결제 요청</button>
    </form>
   </>
      
  );
};

export default Pay;
