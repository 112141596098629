import { useState, useEffect, useContext } from 'react';
import {reqGet} from '../../../service';


export default function ProdDetailPage() {
    // const { lotGridRows, setLotGridRows } = useContext(ProductContext); //모듈 전역 컨텍스트
    // const [HeaderItem, setHeaderItem] = useRecoilState(HeaderAtom);
    // const [item_sample, setItem_sample] = useState();

    useEffect(()=>{
        var params= {};
    },[]);
    
    return (
        <div>
            <label>상세페이지 영역</label>
        </div>
    )
}
