import React, { useState, useEffect } from "react";
import {useNavigate,Link } from "react-router-dom";
import {reqGet,reqSet} from '../../service';
import { RegisterForm, Login } from "../RegisterForm";
import { useAuth } from "../../authContext";
import './loginBox.css';


export default function Popup({ isOpen, handleClose }){
    const [doNotShow ,setDoNotShow] = React.useState(false);
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");

    useEffect(()=>{
        const doNotShowPopup = localStorage.getItem('doNotShowPopup');
        if(doNotShowPopup){
            setDoNotShow(true);
        }
    },[]);
 
    //const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    
    const { login, isLoggedIn ,setIsLoggedIn ,user} = useAuth();


    const validateForm = () => {
      if (!userId) {
        setMessage("아이디를 입력해주세요.");
        return false;
      }
      if (!password) {
        setMessage("비밀번호를 입력해주세요.");
        return false;
      }
      setMessage("");
      return true;
    };


  
    const handleLoginClick = async () => { 
      if (validateForm()) {
        try {
          // Send a login request to the server
          const response = await fetch('api/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ user_id: userId, user_pwd: password })
          });
    
          if (response.ok) {
            const userData = await response.json();
            console.log("로그인 성공: ", userData);
            alert(`${userData.name}님, 로그인에 성공하였습니다!`);
            login(userData); // userData를 login 함수에 전달
            console.log("로그인 상태 : " , );
            navigate('/'); 
            handleClose();
          } else {
            // 로그인 실패 처리
            if (response.status === 401) {
              setMessage("아이디 또는 비밀번호가 잘못되었습니다.");
            } else {
              setMessage("로그인에 실패하였습니다. 다시 시도해주세요.");
            }
          }
        } catch (error) {
          console.error('Error:', error);
          setMessage("로그인 요청 중 문제가 발생했습니다.");
        }
        setUserId(''); 
        setPassword(''); 
      }
    };
    const handleCloseWidthCheckbox = ()=>{
        if(doNotShow){
            localStorage.setItem('doNotShowPopup', true);
        }
        handleClose();
    };

    if(isOpen) return null;
    return(
<div id='myModal' className='modal'>
    <div className='modal-content' style={{
        background: 'white',
        padding: '30px',
        borderRadius: '15px',
        boxShadow: '0 5px 15px rgba(0,0,0,0.2)',
        width: '350px'
    }}>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px'
        }}>
            <label style={{
                fontSize: '24px',
                fontWeight: 'bold',
                textAlign: 'center',
                marginBottom: '20px'
            }}>AntsNest Store</label>
            
            <input 
                type='text' 
                placeholder='사용자 아이디' 
                onChange={(e) => setUserId(e.target.value)}
                style={{
                    padding: '12px',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    fontSize: '14px'
                }}
            />
            
            <input 
                type='password' 
                placeholder='비밀번호'  
                onChange={(e) => setPassword(e.target.value)}
                style={{
                    padding: '12px',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    fontSize: '14px'
                }}
            />
            
            
            <button  className="login-btn"
                onClick={()=>handleLoginClick()}
                style={{
                    padding: '12px',
                    backgroundColor: '#00ADB5',
                    color: 'white',
                    border: 'none',
                    borderRadius: '8px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    cursor: 'pointer'
                }}
            >로그인</button>
        </div>
        
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
        }}>
                      <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '10px'
            }}>
                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                    <input 
                        type='checkbox' 
                        onChange={()=> setDoNotShow(!doNotShow)}
                        style={{cursor: 'pointer'}}
                    />
                    <label style={{fontSize: '13px'}}>아이디 저장하기</label>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                    <input 
                        type='checkbox' 
                        onClick={handleClose}
                        style={{cursor: 'pointer'}}
                    />
                    <label style={{fontSize: '13px'}}>닫기</label>
                </div>
            </div>
            <hr style={{
            margin: '4px 0',
            border: 'none',
            borderTop: '1px solid #eee'
        }}/>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px'
            }}>
                <button style={{
                    padding: '8px 15px',
                    backgroundColor: '#f5f5f5',
                    border: 'none',
                    borderRadius: '6px',
                    fontSize: '14px',
                    cursor: 'pointer'
                }}>아이디/비밀번호 찾기</button>
                
                <Link to='/Register'>
                    <button 
                        onClick={()=>handleClose()}
                        style={{
                            padding: '8px 15px',
                            backgroundColor: '#f5f5f5',
                            border: 'none',
                            borderRadius: '6px',
                            fontSize: '14px',
                            cursor: 'pointer'
                        }}
                    >회원가입</button>
                </Link>
            </div>
        </div>
    </div>
</div>
    );
}