import {useState,useEffect} from 'react';


function Study() {
  const signKey = 'SU5JTElURV9UUklQTEVERVNfS0VZU1RS'; // Replace with your actual key

  const [formData, setFormData] = useState({
    version: '1.0',
    mid: 'INIpayTest',
    goodname: 'C# 객체지향 강의',
    price: '100',
    currency: 'WON',
    buyername: '홍길동',
    buyertel: '010-1234-1234',
    buyeremail: 'test@inicis.com',
    timestamp: '1691636550526',
    oid: 'INIpayTest_1691636550526',
    signature: 'd1ada6bdd9d5a88ead9d3d7742e18ba141ecfcc1b7760584f5c979d6a973fefb',
    mKey: '3a9503069192f207491d4b19bd743fc249a761ed94246c8c42fed06c3cd15a33',
    use_chkfake: 'Y',
    verification: 'c839f8b065e1f24f9177b8936c78fa904e0e79d7fb9595a6dc0125eb9c9d26e6',
    //returnUrl: 'http://web.antsnest.co.kr:1010/INIStdPayReturn.aspx',
    gopaymethod: 'Card',
    offerPeriod: '20150101-20150331',
    acceptmethod: 'HPP(1):no_receipt:va_receipt:below1000:centerCd(Y)',
    languageView: '',
    charset: '',
    payViewType: '',
    nointerest: '',
    quotabase: '',
    INIregno: '',
    merchantData: ''
  });
  useEffect(() => {
    const currentOrigin = window.location.origin;
    setFormData(formData => ({
      ...formData,
      returnUrl: `${currentOrigin}/ReturnPay`,
      closeUrl: `${currentOrigin}/pay/close.aspx`
    }));
  }, []);
const updateVerification = async () => {
  const param2 = `oid=${formData.oid}&price=${formData.price}&signKey=${signKey}&timestamp=${formData.timestamp}`;
  const verificationHash = await computeHash(param2);
  setFormData({ ...formData, verification: verificationHash });
};
useEffect(() => {
  updateVerification();
  const timestamp = Date.now().toString();
  const oid = `${formData.mid}_${timestamp}`;
  const signKey = 'SU5JTElURV9UUklQTEVERVNfS0VZU1RS';//내 해쉬키 입력
  computeHash(signKey).then(mKey => {
    setFormData(formData => ({ ...formData, mKey }));
  setFormData(formData => ({
    ...formData,
    timestamp,
    oid
  }));
}, []);


  // Dummy timestamp generation - Replace with server-side logic
  
  // Compute initial hash values
  computeHash(oid).then(signature => {
    setFormData(formData => ({ ...formData, timestamp, oid, signature }));
  });

  // Compute mKey and verification - Replace signKey with your key
 
  });

async function computeHash(input) {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
}
const handleSubmit = () => {
 window.INIStdPay.pay('study_SendPayForm_id');
  // 이 부분에 이니시스 결제 처리를 적용해주세요 (INIStdPay.pay).
};


  return (
    <div style={{width:300,height:150, color:'black'}}>
    <form id="study_SendPayForm_id" >
  <div style={{ border: '2px #dddddd double', padding: '10px', backgroundColor: '#f3f3f3', textAlign: 'left' }}>
  <input hidden id="version" name="version" type="text" value={formData.version} onChange={handleChange} />
  <input hidden id="mid" name="mid" type="text" value={formData.mid} onChange={handleChange} />
  <input hidden id="goodname" name="goodname" type="text" value={formData.goodname} onChange={handleChange} />
  <input hidden id="oid" name="oid" type="text" value={formData.oid} onChange={handleChange} />
  <input hidden id="price" name="price" type="text" value={formData.price} onChange={handleChange} />
  <input hidden id="currency" name="currency" type="text" value={formData.currency} onChange={handleChange} />
  <input id="buyername" name="buyername" type="text" value={formData.buyername} onChange={handleChange} />
  <input id="buyertel" name="buyertel" type="text" value={formData.buyertel} onChange={handleChange} />
  <input id="buyeremail" name="buyeremail" type="text" value={formData.buyeremail} onChange={handleChange} />
  <br />
  <button type="button" onClick={()=>handleSubmit()}>결제 요청</button>
  <b hidden>timestamp</b> 
  <br />
  <input hidden id="timestamp" name="timestamp" type="text" value={formData.timestamp} onChange={handleChange} style={{ width: '400px' }} />
  <br />
  <b hidden>signature</b> 
  <br />
  <input hidden id="signature" name="signature" type="text" value={formData.signature} onChange={handleChange} style={{ width: '400px' }} />

  <br />
  <b hidden>use_chkfake</b> 
  <br />
  <input hidden id="use_chkfake" name="use_chkfake" type="text" value={formData.use_chkfake} onChange={handleChange} />

  <br />
  <b hidden>verification</b> 
  <br />
  <input hidden id="verification" name="verification" type="text" value={formData.verification} onChange={handleChange} style={{ width: '400px' }} />

  <br />
  <b hidden>returnUrl</b> 
  <br />
  <input hidden id="returnUrl" name="returnUrl" type="text" value={formData.returnUrl} onChange={handleChange} style={{ width: '400px' }} />

  <br />
  <b hidden>MKEY(가맹점키)</b> 
  <br />
  <input hidden id="mKey" name="mKey" type="text" value={formData.mKey} onChange={handleChange} />
</div>


<b>***** 기본 옵션 *****</b>
<div style={{ border: '2px #dddddd double', padding: '10px', backgroundColor: '#f3f3f3', textAlign: 'left' }}>
  <b>gopaymethod</b> : 결제 수단 선택
  EX Card (계약 결제 수단이 존재하지 않을 경우 에러로 리턴)
  사용 가능한 입력 값
  Card,DirectBank,HPP,Vbank,kpay,Swallet,Paypin,EasyPay,PhoneBill,GiftCard,EWallet
  onlypoint,onlyocb,onyocbplus,onlygspt,onlygsptplus,onlyupnt,onlyupntplus
  <input id="gopaymethod" name="gopaymethod" type="text" value={formData.gopaymethod} onChange={handleChange} />
  <b>offerPeriod</b> : 제공기간
  20150101-20150331, [Y2: 년단위결제, M2: 월단위결제, yyyyMMdd-yyyyMMdd: 시작일-종료일]
  <br />
  <input id="offerPeriod" name="offerPeriod" type="text" value={formData.offerPeriod} onChange={handleChange} />
  <br />
  <br />

  <br />
  <b>acceptmethod</b> : acceptmethod
  <br />
   CARDPOINT:SLIMQUOTA(코드-개월:개월):no_receipt:va_receipt:vbank(20150425):va_ckprice:
  <br />
  KWPY_TYPE(0):KWPY_VAT(10|0) 기타 옵션 정보 및 설명은 연동정의보 참조 구분자 ":"
  <br />
  <input hidden id="acceptmethod" name="acceptmethod" type="text" value={formData.acceptmethod} onChange={handleChange} style={{ width: '500px' }} />
</div>

{/* 표시 옵션 */}
<br />
<b>***** 표시 옵션 *****</b>
<div hidden style={{ border: '2px #dddddd double', padding: '10px', backgroundColor: '#f3f3f3', textAlign: 'left' }}>
  <input hidden id="languageView" name="languageView" type="text" value={formData.languageView} onChange={handleChange} />

  <input hidden id="charset" name="charset" type="text" value={formData.charset} onChange={handleChange} />

  <input id="payViewType" name="payViewType" type="text" value={formData.payViewType} onChange={handleChange} style={{ width: '210px' }} />

    <input id="closeUrl" name="closeUrl" type="text" value={formData.closeUrl} onChange={handleChange} style={{ width: '400px' }} />
  </div>
    <button type="button" onClick={()=>handleSubmit()}>결제 요청</button>
    </form>
   </div>
      
  );
};

export default Study;
