import { useState, useEffect, useContext } from 'react';
import { HeaderAtom } from '../../atoms/atomSample';
import { HomeConsumer, HomeContext } from './HomeContext';
import {Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Login } from '../../UICompToolkit/LoginView';
import { Navigate } from 'react-router-dom';

import { AuthContext, useAuth } from "../../authContext";

export default function Home() {
    const { login,user } = useAuth();
    const { isLoggedIn } = useContext(AuthContext);
    const { lotGridRows, setLotGridRows } = useContext(HomeContext); //모듈 전역 컨텍스트
    const [HeaderItem, setHeaderItem] = useRecoilState(HeaderAtom);
    const [item_sample, setItem_sample] = useState();

    useEffect(() => {
        if (isLoggedIn && user) {

        }
      }, [isLoggedIn]); // user를 의존성 배열에 추가합니다.
     // 로그인 상태 변경시에만 실행
    
      // 로그인 상태가 아니면 로그인 페이지로 리다이렉트
      if (!isLoggedIn) {
        return <Navigate to="/login" />;
      }
      
    const getItemSample = (item_sample) => {
        fetch(`/test/getItemSample?item_sample=${item_sample}`, { credentials: 'include' })
            .then((res) => res.json())
            .then((msg) => setItem_sample(msg))
            .catch(err => alert('Error : getItemSample' + err));
    }
    return (
        <div>
            <SubHome />
            <Link to='/pay'>pay</Link>
            <input type='text' onChange={(e) => { setLotGridRows(e.target.value) }} />
            <button onClick={() => getItemSample(item_sample)}>전송</button>
            <HomeConsumer />
        </div>
    )
}

export function SubHome() {
    const { lotGridRows, setLotGridRows } = useContext(HomeContext); //모듈 전역 컨텍스트

    return (
        <div>
            Ants Front Tamplete{lotGridRows}
        </div>
    )
}