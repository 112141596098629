import {useState,useEffect} from 'react';
import { useRecoilState } from 'recoil';
import { CartAtom } from '../atoms/accountAtom';
import { useAuth } from "../authContext";
import FlatDropDown from '../UICompToolkit/FlatDropDown';
import { Navigate } from 'react-router-dom';
function AccountProduct() {
  const [CartItem] = useRecoilState(CartAtom);//CartItem
  const [total, setTotal] = useState(0);//CartItem
  const [ItemName, setItemName] = useState(['0']);//CartItem
  const { isLoggedIn,user } = useAuth();
  
  useEffect(()=>{
    SumPrice();
    SumItemName();
},[])

useEffect(() => {
  if (isLoggedIn && user) {
  }
}, [isLoggedIn]); // user를 의존성 배열에 추가합니다.
// 로그인 상태 변경시에만 실행



const SumPrice=()=>{
    if(CartItem){
        var sumValue = total;
        CartItem.forEach(item => {
            sumValue = sumValue+=item.price;
        });
    }
    setTotal(sumValue);
}
const SumItemName=()=>{
  if(CartItem){
      var sumItemName = '';
      CartItem.forEach(item => {
          sumItemName = sumItemName +=item.title+',';
      });
  }
  setItemName(sumItemName);
}
  const signKey = 'SU5JTElURV9UUklQTEVERVNfS0VZU1RS'; // Replace with your actual key

  const [formData, setFormData] = useState({
    version: '1.0',
    mid: 'INIpayTest',
    goodname: '상품명',
    currency: 'WON',
    buyername: user? user.id:'Buyer',
    buyertel: user? user.phone:'000-000-0000',
    buyeremail: user? user.email:'email',
    use_chkfake: 'Y',
    //returnUrl: 'http://web.antsnest.co.kr:1010/INIStdPayReturn.aspx',
    gopaymethod: 'Card',
    offerPeriod: '20150101-20150331',
    acceptmethod: 'HPP(1):no_receipt:va_receipt:below1000:centerCd(Y)',
    languageView: '',
    charset: '',
    payViewType: '',
    nointerest: '',
    quotabase: '',
    INIregno: '',
    merchantData: ''
  });
 
  useEffect(() => {
    const currentOrigin = window.location.origin;
    setFormData(formData => ({
      ...formData,
      returnUrl: `${currentOrigin}/ReturnPay`,
      closeUrl: `${currentOrigin}/`
    }));
  }, []);

  const updateVerification = async (oid, timestamp) => {
    const param2 = `oid=${oid}&price=${total}&signKey=${signKey}&timestamp=${timestamp}`;
    const verificationHash = await computeHash(param2);
    return verificationHash;
  };

useEffect(()=>{
  setFormData(formData => ({...formData,total:total,goodname:ItemName}))
} ,[total,ItemName]);
useEffect(() => {
  if (formData.mKey && formData.oid && formData.signature && formData.verification && formData.timestamp) {
    // 모든 formData가 세팅된 후에 결제 처리를 진행
    window.INIStdPay.pay('SendPayForm_id');
  }
}, [formData]); 

async function computeHash(input) {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
}

// 로그인 상태가 아니면 로그인 페이지로 리다이렉트
if (!isLoggedIn) {
  return <Navigate to="/login" />;
}
const handleSubmit = async () => {
  try {
    const mid = 'INIpayTest'; // 상점아이디
    const timestamp = Date.now().toString();
    const oid = `${mid}_${timestamp}`;
    const signKey = 'SU5JTElURV9UUklQTEVERVNfS0VZU1RS'; // 내 해쉬키 입력

    // mKey 계산
    const mKey = await computeHash(signKey);
    // signature 계산
    const signature = await computeHash(oid);
    // verification 업데이트
    const verificationHash = await updateVerification(oid, timestamp);

    // formData 업데이트
    setFormData(prevFormData => ({
      ...prevFormData,
      mKey,
      oid,
      signature,
      verification: verificationHash,
      timestamp, // verification 값 추가
    }));

    //alert(JSON.stringify(formData));
    // 이니시스 결제 처리
    //window.INIStdPay.pay('SendPayForm_id');
  } catch (error) {
    console.error('Error occurred during handleSubmit:', error);
  }
};
const selectOption=['지불 방식 선택','Card','DirectBank','HPP'];
  return (
    <div>
  <form id="SendPayForm_id" >
  <div style={{display:'flex', width:'100%' ,justifyContent:'center'}}>
  <input hidden id="version" name="version" type="text" value={formData.version} onChange={handleChange} />
  <input hidden id="mid" name="mid" type="text" value={formData.mid} onChange={handleChange} />
  <input hidden id="goodname" name="goodname" type="text" value={ItemName} onChange={handleChange} />
  <input hidden id="oid" name="oid" type="text" value={formData.oid} onChange={handleChange} />
  <input hidden id="currency" name="currency" type="text" value={formData.currency} onChange={handleChange} />
  <input hidden id="buyername" name="buyername" type="text" value={formData.buyername} onChange={handleChange} />
  <input hidden id="buyertel" name="buyertel" type="text" value={formData.buyertel} onChange={handleChange} />
  <input hidden id="buyeremail" name="buyeremail" type="text" value={formData.buyeremail} onChange={handleChange} />
  <input hidden id="timestamp" name="timestamp" type="text" value={formData.timestamp} onChange={handleChange} style={{ width: '400px' }} />
  <input hidden id="signature" name="signature" type="text" value={formData.signature} onChange={handleChange} style={{ width: '400px' }} />
  <input hidden id="price" name="price" type="text" value={total} onChange={handleChange} />
  <div style={{display:'flex',flexDirection:'row', width: '300px', gap:'10px', alignItems: 'center'}}>
  <FlatDropDown ObjectArray={selectOption} style={{ height: '50px' }} ></FlatDropDown>
  <input hidden id="gopaymethod" name="gopaymethod" type="text" value={formData.gopaymethod} onChange={handleChange} />
  <button style={{border:'none', borderRadius:'5px', backgroundColor: '#00adb5', color: '#fff',fontSize:'16px', fontWeight:'bold', height:'40px'}} type="button" onClick={()=>handleSubmit()}>결제 요청</button>
  </div>
  {/* <div>{'TEST:'+JSON.stringify(user)}</div> */}
  <div hidden style={{ border: '2px #dddddd double', padding: '10px', backgroundColor: '#f3f3f3', textAlign: 'left' }}>
  <input hidden id="offerPeriod" name="offerPeriod" type="text" value={formData.offerPeriod} onChange={handleChange} />
  <input hidden id="use_chkfake" name="use_chkfake" type="text" value={formData.use_chkfake} onChange={handleChange} />
  <input hidden id="verification" name="verification" type="text" value={formData.verification} onChange={handleChange} style={{ width: '400px' }} />
  <input hidden id="returnUrl" name="returnUrl" type="text" value={formData.returnUrl} onChange={handleChange} style={{ width: '400px' }} />
  <input hidden id="mKey" name="mKey" type="text" value={formData.mKey} onChange={handleChange} />
  <input hidden id="acceptmethod" name="acceptmethod" type="text" value={formData.acceptmethod} onChange={handleChange} style={{ width: '500px' }} />
  <input hidden id="languageView" name="languageView" type="text" value={formData.languageView} onChange={handleChange} />
  <input hidden id="charset" name="charset" type="text" value={formData.charset} onChange={handleChange} />
  <input id="payViewType" name="payViewType" type="text" value={formData.payViewType} onChange={handleChange} style={{ width: '210px' }} />
  <input id="closeUrl" name="closeUrl" type="text" value={formData.closeUrl} onChange={handleChange} style={{ width: '400px' }} />
  </div>
    </div>
    </form>
    </div>
  );
};

export default AccountProduct;
