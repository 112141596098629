import logo from './logo.svg';
import './App.css';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './screen/Home';
import { HomeContext,HomeProvider } from './screen/Home/HomeContext';
import { AdminContext,AdminProvider } from './screen/Admin/AdminContext';
import { ProductContext,ProductProvider } from './screen/Product/ProductContext';
import { ProdDetailPageContext,ProdDetailPageProvider } from './screen/Product/ProdDetailPage/ProdDetailPageContext';
import MyPage from './UICompToolkit/MyPage';
import React,{useEffect,useState} from 'react';
import Product from './screen/Product';
import ProdDetailPage from './screen/Product/ProdDetailPage';
import {Login} from './UICompToolkit/LoginView';
import Pay from './Pay';
import ReturnPay from './ReturnPay';
import Store from './screen/Store';
import Admin from './screen/Admin';
import Popup from './UICompToolkit/LoginBox';
import { AuthContext, useAuth } from "./authContext";

import { RegisterForm } from './UICompToolkit/RegisterForm';

function App() {
      // 팝업 열리고 닫는거
      const [isPopupOpen, setIsPopupOpen] = useState(false);
      const { login,user } = useAuth();
      useEffect(()=>{
        // console.log(JSON.stringify(user))
        //setIsPopupOpen(user ? true:false); // false:true에서 뒤집어서 홈페이지 시작 시 로그인 창 열림 수정
      },[user]);
  
      const closePopup = () =>{
        setIsPopupOpen(true);
      }
  return (
    <>
    <Popup isOpen={isPopupOpen} handleClose={closePopup}/>
    <div className="App">
    <header className="App-header">
      <div style={{display:'flex', width:'90%',justifyContent:'space-between'}}>
            <Link to='/'><img src="/StoreLongLogo.png" alt="AntsStore" style={{width:'200px'}}/></Link>
            {user? <Link to='/MyPage'><h1 className="text-3xl font-bold text-center">{user.id}</h1></Link> : 
            <Link to='/MyPage' style={{ color: '#1e5175' }}><img src = "./character.png" alt="Myprofile" style={{marginTop:'10px', width:'50px'}}/></Link>}
      </div>
      </header>
      
      <Routes>
        <Route exact path="/Home" element={
        <HomeProvider>
          <Home />
          </HomeProvider>} key="Home" />

          <Route exact path="/Login" element={<Login/>} key="Login" /> 
          <Route exact path="/" element={
          <Store />
          } key="Store" />

          <Route exact path="/Product" element={
            <ProductProvider>
              <Product />
            </ProductProvider>} key="Product" />


            <Route exact path="/ProdDetailPage" element={
              <ProdDetailPageProvider>
              <ProdDetailPage/>
              </ProdDetailPageProvider>} key="ProdDetailPage" />

              <Route exact path="/MyPage" element={
              <HomeProvider>
                <MyPage/>
              </HomeProvider>} key="MyPage" />
              <Route exact path="/Admin" element={
              <AdminProvider>
                <Admin/>
              </AdminProvider>} key="Admin" />
              <Route exact path="/Register" element={
              <HomeProvider>
                <RegisterForm/>
              </HomeProvider>} key="Register" />
          
          <Route exact path="/Pay" element={
            <HomeProvider>
              <Pay />
            </HomeProvider>} key="Pay" />
          <Route exact path="/ReturnPay" element={
        <HomeProvider>
          <ReturnPay />
          </HomeProvider>} key="ReturnPay" />
      </Routes>
    </div>
    </>
  );
}

export default App;
