import React, { CreateRef ,useState, useEffect, createRef } from 'react';
import './style.css';

function FlatDropDown(Props) {
    let [ItemSource,setItemSource] = useState(Props.ObjectArray);
    useEffect(() => {
        InitializieComponent();
      },[]);
      useEffect(() => {
        setItemSource(Props.ObjectArray);
      },[Props.ObjectArray]);
      let DropDownBox = createRef();
      let styleObj = { width: Props.width };
      let InitializieComponent =()=>{
          let DropDownItem = DropDownBox.current.getElementsByTagName("select")[0];
          //컴보박스 아이템을 Div 에 등록
          var SelectedValueDiv = document.createElement("DIV");
          SelectedValueDiv.setAttribute("class", "select-selected");
          SelectedValueDiv.innerHTML = '<li>'+DropDownItem.options[DropDownItem.selectedIndex].innerHTML+'</li>';
          
          DropDownBox.current.appendChild(SelectedValueDiv);
          /*for each element, create a new DIV that will contain the option list:*/
          var ItemListDiv = document.createElement("DIV");
          ItemListDiv.setAttribute("class", "select-items select-hide");
          var NewSelectedValueBox;  
          for (var i = 1; i < DropDownItem.length; i++) {
            NewSelectedValueBox = document.createElement("DIV");
            NewSelectedValueBox.innerHTML = DropDownItem.options[i].innerHTML;
            NewSelectedValueBox.addEventListener("click", function(e) {
                let SelectDom = this.parentNode.parentNode.getElementsByTagName("select")[0];
                var prevItem = this.parentNode.previousSibling;
                
                for (var j = 0; j < SelectDom.length; j++) {
                  if (SelectDom.options[j].innerHTML == this.innerHTML) {
                    SelectDom.selectedIndex = j;
                    prevItem.innerHTML = '<li>'+this.innerHTML+'</li>';//새 선택값으로 변경
                    let HideItem = this.parentNode.getElementsByClassName("same-as-selected");
                    for (var k = 0; k < HideItem.length; k++) {
                      HideItem[k].removeAttribute("class");
                    }
                    this.setAttribute("class", "same-as-selected");
                    break;
                  }
                }
                prevItem.click();
            });
            ItemListDiv.appendChild(NewSelectedValueBox);
          }
          DropDownBox.current.appendChild(ItemListDiv);

          SelectedValueDiv.addEventListener("click", function(e) {
              /*선택상자를 닫아주는 이벤트:*/
              e.stopPropagation();
              closeAllSelect(this);
              this.nextSibling.classList.toggle("select-hide");
              this.classList.toggle("select-arrow-active");
            });

        function closeAllSelect(elmnt) {
          var i, arrNo = [];
          let 아이템들=[];
          let 선택된아이템 =[];
          if(DropDownBox==null && DropDownBox.current==null){
            아이템들 =  DropDownBox.current.getElementsByClassName("select-selected");
            선택된아이템 = DropDownBox.current.getElementsByClassName("select-selected");
          }

          for (i = 0; i < 선택된아이템.length; i++) {
            if (elmnt == 선택된아이템[i]) {
              arrNo.push(i)
            } else {
              선택된아이템[i].classList.remove("select-arrow-active");
            }
          }
          for (i = 0; i < 아이템들.length; i++) {
            if (arrNo.indexOf(i)) {
              아이템들[i].classList.add("select-hide");
            }
          }
        }
        document.addEventListener("click", closeAllSelect);
      }
  return (
    <div ref={DropDownBox} className="FlatDropDown" style={styleObj}>
    <select>
    {ItemSource.map((Row, index) => (<option key={index} value={index}>{Row}</option> ))}
    </select>
  </div>
  );
}

export default FlatDropDown;