import { createContext, useContext, useState } from 'react';

const ProductContext = createContext();

const ProductProvider = ({ children }) => {
  const [lotGridRows, setLotGridRows] = useState('');

  return (
    <ProductContext.Provider value={
      { lotGridRows, setLotGridRows }
    }>
      {children}
    </ProductContext.Provider>
  );
};

const ProductConsumer = ({ children }) => {
  const value = useContext(ProductContext);

  return (
    <div>
      Lot grid rows: {value.lotGridRows}
    </div>
  );
};

export { ProductProvider, ProductConsumer, ProductContext };
