import { createContext, useContext, useState } from 'react';

const AdminContext = createContext();

const AdminProvider = ({ children }) => {
  const [lotGridRows, setLotGridRows] = useState('');

  return (
    <AdminContext.Provider value={
      { lotGridRows, setLotGridRows }
    }>
      {children}
    </AdminContext.Provider>
  );
};

const AdminConsumer = ({ children }) => {
  const value = useContext(AdminContext);

  return (
    <div>
      Lot grid rows: {value.lotGridRows}
    </div>
  );
};

export { AdminProvider, AdminConsumer, AdminContext };
